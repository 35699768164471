import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

// Import translation files directly for SSR
import translationEN from './locales/en/translation.json';
import translationPL from './locales/pl/translation.json';

const resources = {
  en: {
    translation: translationEN,
  },
  pl: {
    translation: translationPL,
  },
};

const isBrowser = typeof window !== 'undefined';

// Initialize i18next instance
const i18nextInstance = i18n.createInstance();

// Configure i18next
const i18nextConfig = {
  resources,
  fallbackLng: 'en',
  debug: process.env.NODE_ENV === 'development',
  interpolation: {
    escapeValue: false,
  },
  detection: {
    order: ['path', 'cookie', 'localStorage', 'navigator'],
    caches: ['cookie'],
  },
};

// Only add backend config if in browser
if (isBrowser) {
  i18nextConfig.backend = {
    loadPath: '/locales/{{lng}}/{{ns}}.json',
  };
}

// Initialize with react-i18next
i18nextInstance.use(initReactI18next);

// Add browser-specific plugins only if in browser
if (isBrowser) {
  i18nextInstance.use(Backend).use(LanguageDetector);
}

// Initialize with config
i18nextInstance.init(i18nextConfig);

export default i18nextInstance;
